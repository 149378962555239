.logo {
  height: 180px;
  width: 180px;
}

.validIcon {
  height: 20%;
  width: 20%;
}

.validationPageContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100vw;
  min-height: calc(100vh - 166.172px);
  background-color: #FFEA44;
  color: #1101CB;
  font-family: PT Mono;
  padding: 5%;
}