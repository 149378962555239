.main-layout {
  width: 100%;
  height: 100vh;
}

.footer {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 20px;
  padding-bottom: 10px;
  color: #1ACFFF;
  background-color: #1101CB;
}

.footer-header-container {
  display: flex;
  text-transform: uppercase;
}

.footer-header-container-left {
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
  font-size: 0.8em;
  font-weight: 300;
}

.footer-header-container-center {
  flex-grow: 1;
  justify-content: center;
}

.footer-header-container-right {
  flex-grow: 1;
  justify-content: flex-end;
  text-align: right;
  font-size: 0.8em;
  font-weight: 400;
}

.footer-header {
  font-weight: 900;
  font-style: normal;
  letter-spacing: .01em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}

.footer-hr {
  border-color: #1ACFFF;
  
}

.footer-links {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-link {
  color: #1ACFFF !important;
  font-weight: 400;
}

.copyright {
  text-transform: uppercase;
  text-align: left;
  font-weight: 400;
  margin-bottom: 0;
}

